var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex flex-col" },
    [
      _c(
        "nav",
        {
          staticClass:
            "flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end"
        },
        [
          _c("v-back", {
            attrs: { fallback: _vm.$router.resolve({ name: "teams-overview" }) }
          }),
          _c("p", {
            staticClass:
              "font-semibold w-full text-center -ml-7 pointer-events-none",
            domProps: {
              textContent: _vm._s(
                _vm.$t("the_invited_users_overview.label_invited_helpers")
              )
            }
          })
        ],
        1
      ),
      !_vm.loading && !_vm.error
        ? _c(
            "v-scrollable",
            { ref: "scrollable", staticClass: "flex-grow" },
            [
              _vm.users !== null
                ? _c("users-list", { attrs: { users: _vm.users } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.loading ? _c("v-loading") : _vm._e(),
      _vm.error
        ? _c("v-error", {
            model: {
              value: _vm.error,
              callback: function($$v) {
                _vm.error = $$v
              },
              expression: "error"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }