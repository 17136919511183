<template>
  <div class="flex flex-col">
    <nav class="flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end">
      <v-back :fallback="$router.resolve({ name: 'teams-overview'})"/>
      <p
        v-text="$t('the_invited_users_overview.label_invited_helpers')"
        class="font-semibold w-full text-center -ml-7 pointer-events-none"
      ></p>
    </nav>
    <v-scrollable
      v-if="!loading && !error"
      class="flex-grow"
      ref="scrollable"
    >
      <users-list
        v-if="users !== null"
        :users="users"/>
    </v-scrollable>
    <v-loading v-if="loading"/>
    <v-error
      v-if="error"
      v-model="error"/>
  </div>
</template>

<script>
  import infinityScroll from '@/mixins/infinityScroll';
  import UsersList from '@/components/lists/UsersList.vue';
  import VScrollable from '@/components/common/VScrollable.vue';
  import VLoading from '@/components/common/VLoading.vue';
  import VError from '@/components/common/VError.vue';
  import VBack from '@/components/common/VBack.vue';

  export default {
    name: 'TheInvitedUsersOverview',
    components: {
      UsersList,
      VScrollable,
      VLoading,
      VError,
      VBack,
    },
    mixins: [infinityScroll],
    data() {
      return {
        loading: false,
        error: null,
      };
    },
    computed: {
      users() {
        return this.$store.getters['user/list'];
      },
      event() {
        return this.$store.getters['auth/event'];
      },
    },
    created() {
      this.loading = true;

      this.$store.dispatch('user/loadInvitedForEvent', this.event.id)
        .then(() => {
          this.loading = false;

          this.$nextTick(() => {
            this.attachInfiniteScroll(this.$refs.scrollable, () => {
              this.$store.dispatch('user/loadInvitedForEvent', this.event.id);
            });
          });
        })
        .catch((error) => {
          this.loading = false;
          this.error = error;
        });
    },
    beforeDestroy() {
      this.$store.commit('user/clearList');
    },
  };
</script>
